/** @jsx jsx */
import { jsx } from 'theme-ui';
import ProtectedRoute from 'src/components/composite/ProtectedRoute';
import { ChartContextWrapper } from 'src/components/composite/chart-generator/wrappers/ChartContextWrapper';
import InternationalComparisonChartWrapper from 'src/components/composite/chart-generator/wrappers/InternationalComparisonChartWrapper';

export default function () {
  return (
    <ChartContextWrapper>
      <ProtectedRoute path="/international-chart" component={InternationalComparisonChartWrapper} />
    </ChartContextWrapper>
  );
}
